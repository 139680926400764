// Copyright 2024 The LUCI Authors.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Outlet } from 'react-router-dom';

import { LabsWarningAlert } from '@/common/components/labs_warning_alert';

import { FEEDBACK_BUGANIZER_BUG_ID } from '../constants/feedback';
import { colors } from '../theme/colors';

/**
 * Layout used in `/ui/fleet/labs` to signify that a page is experimental.
 */
export function Component() {
  return (
    <>
      <LabsWarningAlert
        sx={{
          backgroundColor: colors.cyan[50],
          position: 'sticky',
          left: 0,
          width: '100%',
          boxSizing: 'border-box',
        }}
        feedbackUrlArgs={{ bugComponent: FEEDBACK_BUGANIZER_BUG_ID }}
      />
      <Outlet />
    </>
  );
}
